 $(document).foundation();

$(document).ready(function(){
var card =$('.product--card');
var swatch = $('.swatch-image');
var product =$('.product--image');
var swatchToggle = $('.swatch--toggle');

swatchToggle.click(function(){
    // console.log('clicked');
    $(this).siblings('.product--image').toggleClass('flip_product--image');
    // $(this).siblings('.product--image').toggleClass('hide_product--image');
$(this).siblings('.swatch--image').toggleClass('show_swatch--image');
});

var show = $('.open-menu');
var close = $('.close');
var mobileMenu =$('.mobile-menu');

show.click(function(){
  
    mobileMenu.show();
});

close.click(function(){
    
    mobileMenu.hide();
});

});










